export const useHeader = defineStore('header', () => {
  // State
  const back: Ref<boolean> = ref(false);
  const close: Ref<boolean> = ref(false);
  const burger: Ref<boolean> = ref(false);
  const onBack: Ref<null | (() => void)> = ref(null);
  const onClose: Ref<null | (() => void)> = ref(null);

  // Methods
  const configure = (
    showBurger: boolean,
    onBackCallback: (() => void) | null,
    onCloseCallback: (() => void) | null,
  ): void => {
    back.value = onBackCallback !== null;
    close.value = onCloseCallback !== null;
    burger.value = showBurger;

    onBack.value = onBackCallback;
    onClose.value = onCloseCallback;
  };

  return {
    // State
    back,
    close,
    burger,
    onBack,
    onClose,

    // Methods
    configure,
  };
});
